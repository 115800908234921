<template>
  <template v-if="selectedCustomPage">
    <div
      v-if="selectedCustomPage.Type === 'page'"
      class="--page"
      v-html="selectedCustomPage.Text"
    />
    <div
      v-if="selectedCustomPage.Type === 'gallery'"
      :class="`${selectedCustomPage.ShowFooter && selectedCustomPage.ShowHeader ? '--gallery' : '--gallery__full-page'}`"
    >
      <div
        v-if="selectedCustomPage.ShowFooter || selectedCustomPage.ShowHeader"
        class="v-row v-mb-lg"
      >
        <div class="v-col-12">
          <h1 v-html="selectedCustomPage.Title" />
        </div>
        <div class="v-col-12">
          <span v-html="selectedCustomPage.Description" />
        </div>
      </div>
      <common-gallery
        :auto-play-delay="0"
        :images="selectedCustomPage.Images"
        :items-number="2"
        :show-thumbs="false"
      />
    </div>
  </template>
</template>

<script setup lang="ts">
import type { CustomPage } from '~types/settingsVariants'

import type { GUID } from '@arora/common'

const { id } = defineProps<{ id: GUID }>()

const appConfig = useAppConfig()
const selectedCustomPage = computed<CustomPage | undefined>(() => {
  return (appConfig.VueSettingsPreRun.CustomPages ?? []).find((page) => page.ID === id)
})
</script>
